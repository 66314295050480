import React from 'react';
import { useTranslation } from 'react-i18next';
import { getInitialTime, getCurrentTime } from '../../utils/misc';
import { Icons } from '../Icons';

function GameSelection({ lastGameId, onSelectGame, gameData, language }) {
  const { t } = useTranslation();

  const today = getCurrentTime();
  const firstDay = getInitialTime(language);

  const numberOfDays = today.diff(firstDay, 'days') + 1;

  const options = [...Array(numberOfDays)].map((_, i) => ({
    date: today.subtract(i, 'day'),
    gameId: lastGameId - i,
  }));

  const getGameStatus = (gameId) => {
    if (gameData[gameId] === undefined) {
      return '';
    }

    const targetGameData = gameData[gameId];

    if (targetGameData.foundWord) {
      return t('Got it');
    }

    if (targetGameData.gaveUp) {
      return t('Gave up');
    }

    if (
      !targetGameData.gaveUp &&
      !targetGameData.foundWord &&
      targetGameData.numberOfAttempts > 0
    ) {
      return t('In progress');
    }

    return '';
  };

  const randomOptions = options.filter(
    (option) =>
      getGameStatus(option.gameId) === '' && option.gameId !== lastGameId
  );

  const selectRandomGame = () => {
    const selectedIndex = Math.floor(Math.random() * randomOptions.length);
    const selectedGameId = randomOptions[selectedIndex].gameId;
    onSelectGame(selectedGameId, true);
  };

  const dateFormat = language === 'en' ? 'ddd, MMM D' : 'ddd, D MMM';

  return (
    <div>
      <p>{t('previousModalText')}</p>
      <div>
        {randomOptions.length > 0 && (
          <div style={{ padding: '10px 0 15px 0', textAlign: 'center' }}>
            <button type="button" className="button" onClick={selectRandomGame}>
              <div className="game-selection-button">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icons.Random />
                  {t('random')}
                </div>
              </div>
            </button>
          </div>
        )}
        {options.map((option) => (
          <div style={{ padding: '5px 0' }} key={option.gameId}>
            <button
              type="button"
              className="button button-full"
              onClick={() => onSelectGame(option.gameId)}
            >
              <div className="game-selection-button">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>#{option.gameId}</div>
                  <div style={{ fontSize: 14, marginLeft: 10 }}>
                    {option.date.format(dateFormat)}
                  </div>
                </div>
                <div>{getGameStatus(option.gameId)}</div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export { GameSelection };
