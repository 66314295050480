import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      privacyPolicy: 'privacy policy',
      privacyPolicyLink: '/privacy.html',
      cookieDialog: {
        p1: 'Contexto uses cookies according to our',
        p2: 'By continuing to play, you agree with it.',
      },
      'How to play': 'How to play',
      Tip: 'Tip',
      'Give up': 'Give up',
      'Previous games': 'Previous games',
      'Closest words': 'Closest words',
      Settings: 'Settings',
      Credits: 'Credits',
      Language: 'Language',
      game: 'Game',
      attempts: 'Guesses',
      tips: 'Tips',
      inputPlacehoder: 'type a word',
      loading: 'Loading...',
      oops: 'Oops... there was an error!',
      howToPlayText: {
        p1: 'Find the secret word. You have unlimited guesses.',
        p2: 'The words were sorted by an artificial intelligence algorithm according to how similar they were to the secret word.',
        p3: 'After submitting a word, you will see its position. The secret word is number 1.',
        p4: 'The algorithm analyzed thousands of texts. It uses the context in which words are used to calculate the similarity between them.',
      },
      giveUpModalText: 'Are you sure you want to give up?',
      Yes: 'Yes',
      No: 'No',
      previousModalText: 'Select a previous game to play:',
      'Got it': 'Got it',
      'Gave up': 'Gave up',
      'In progress': 'In progress',
      closestModal: {
        p1a: "Today's word",
        p1b: 'was:',
        p2: 'These were the {{number}} closest words:',
      },
      settingsModal: {
        tips: 'Tips',
        easy: 'Easy',
        easyExplanation:
          "The tip's position will be half the position of your closest word",
        medium: 'Medium',
        mediumExplanation:
          "The tip's position will be one less than your closest word",
        hard: 'Hard',
        hardExplanation: "The tip's position will be random",
        theme: 'Theme',
        light: 'Light',
        dark: 'Dark',
        order: 'Sort by',
        similarity: 'Similarity',
        guess: 'Guess order',
        other: 'Other',
        hideCountdown: 'Hide countdown to next word',
      },
      infoModal: {
        p1: 'A game by',
        p2a: 'Inspired by',
        p2b: '.',
        p3: 'The data used to calculate the proximity between words come from',
        sourceName: "Stanford's NLP Group",
        sourceLink: 'https://nlp.stanford.edu/projects/glove/',
        p4: 'The website uses cookies to collect statistics and show ads. More info in the',
        version: 'Version',
      },
      end: {
        played: 'I played',
        andGotIt: 'and got it',
        butGaveUp: 'but I gave up',
        with: 'in',
        attempts: 'guesses',
        and: 'and',
        tips: 'tips',
        betterLuck: 'Better luck in the next one!',
        congrats: 'Congrats!',
        youGaveUpThe: 'You gave up the',
        youGotThe: 'You got the',
        word: 'word',
        in: 'in',
        theWordWas: 'The word was',
        share: 'Share',
        anyway: 'anyway',
        copied: 'Copied!',
        error: 'Error :/',
        done: 'Done!',
      },
      calculating: 'Calculating...',
      theWord: 'The word',
      alreadyGuessed: 'was already guessed',
      countdownText: 'Next word in',
      random: 'Random',
      FeedbackTitle: 'Help us improve',
      FeedbackText: {
        pt1: 'Fill this small form and tell us your thoughts about Contexto to help us make the game better.',
        pt2: " It takes less than 5 minutes and we don't collect any personal information.",
      },
      FeedbackButton: 'Take the Survey',
      Feedback: 'Feedback',
    },
  },
  pt: {
    translation: {
      privacyPolicy: 'política de privacidade',
      privacyPolicyLink: '/privacidade.html',
      cookieDialog: {
        p1: 'O Contexto utiliza cookies de acordo com a sua',
        p2: 'Ao continuar jogando, você concorda com ela.',
      },
      'How to play': 'Como jogar',
      Tip: 'Dica',
      'Give up': 'Desistir',
      'Previous games': 'Jogos anteriores',
      'Closest words': 'Palavras mais próximas',
      Settings: 'Configurações',
      Credits: 'Créditos',
      Language: 'Idioma',
      game: 'Jogo',
      attempts: 'Tentativas',
      tips: 'Dicas',
      inputPlacehoder: 'digite uma palavra',
      loading: 'Carregando...',
      oops: 'Ixx... deu erro aqui!',
      howToPlayText: {
        p1: 'Descubra a palavra secreta. Você pode tentar quantas vezes precisar.',
        p2: 'As palavras foram ordenadas por um algoritmo de inteligência artificial de acordo com a similaridade delas com a palavra secreta.',
        p3: 'Depois de enviar uma palavra, você verá a posição em que ela está. A palavra secreta é a número 1.',
        p4: 'O algoritmo analisou milhares de textos em Português. Ele utiliza o contexto em que as palavras são utilizadas para calcular a similaridade entre elas.',
      },
      giveUpModalText: 'Tem certeza de que quer desistir mesmo?',
      Yes: 'Sim',
      No: 'Não',
      previousModalText: 'Selecione um jogo anterior para jogar:',
      'Got it': 'Acertou',
      'Gave up': 'Desistiu',
      'In progress': 'Em andamento',
      closestModal: {
        p1a: 'A palavra de hoje',
        p1b: 'era:',
        p2: 'Essas eram as {{number}} palavras mais próximas:',
      },
      settingsModal: {
        tips: 'Dicas:',
        easy: 'Fáceis',
        easyExplanation:
          'A posição da dica será a metade da posição da sua palavra mais próxima',
        medium: 'Médias',
        mediumExplanation:
          'A posição da dica será uma a menos que a da sua palavra mais próxima',
        hard: 'Difíceis',
        hardExplanation: 'A posição da dica será aleatória',
        theme: 'Tema',
        light: 'Claro',
        dark: 'Escuro',
        order: 'Ordenar por',
        similarity: 'Similaridade',
        guess: 'Ordem de tentativa',
        other: 'Outras',
        hideCountdown: 'Ocultar contagem regressiva',
      },
      infoModal: {
        p1: 'Um jogo da',
        p2a: 'Inspirado no',
        p2b: '.',
        p3: 'A base de dados utilizada para calcular a proximidade das palavras vem do',
        sourceName: 'NILC',
        sourceLink: 'http://www.nilc.icmc.usp.br/embeddings',
        p4: 'O site utiliza cookies para coletar estatísticas e exibir propagandas. Saiba mais lendo a',
        version: 'Versão',
      },
      end: {
        played: 'Joguei',
        andGotIt: 'e acertei',
        butGaveUp: 'mas desisti',
        with: 'com',
        attempts: 'tentativas',
        and: 'e',
        tips: 'dicas',
        betterLuck: 'Mais sorte na próxima!',
        congrats: 'Parabéns!',
        youGaveUpThe: 'Você desistiu da',
        youGotThe: 'Você acertou a',
        word: 'palavra',
        in: 'em',
        theWordWas: 'A palavra era',
        share: 'Compartilhe',
        anyway: 'mesmo assim',
        copied: 'Copiado!',
        error: 'Deu erro :/',
        done: 'Pronto!',
      },
      calculating: 'Calculando...',
      theWord: 'A palavra',
      alreadyGuessed: 'já foi',
      countdownText: 'Próxima palavra em',
      random: 'Aleatório',
      FeedbackTitle: 'Ajude-nos a melhorar',
      FeedbackText: {
        pt1: 'Responda esse pequeno questionário e nos diga o que você acha do Contexto para nos ajudar a tornar o jogo cada vez melhor.',
        pt2: ' Demora menos de 5 minutinhos e não coletamos nenhuma informação pessoal.',
      },
      FeedbackButton: 'Responder o Questionário',
      Feedback: 'Feedback',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pt', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: 'pt',
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
