if (!window.gtag) {
  window.gtag = () => {};
}

const languageExt = (language) => (language === 'en' ? '_en' : '');

const eventName = (name, language) => `${name}${languageExt(language)}`;

const track = {
  start: (language) => {
    window.gtag('event', eventName('start', language), {
      event_category: 'game',
    });
  },

  guess: (language) => {
    window.gtag('event', eventName('guess', language), {
      event_category: 'game',
    });
  },

  guessAfterWin: (language) => {
    window.gtag('event', eventName('guess_after_win', language), {
      event_category: 'game',
    });
  },

  tip: (language) => {
    window.gtag('event', eventName('tip', language), {
      event_category: 'game',
    });
  },

  win: (language) => {
    window.gtag('event', eventName('win', language), {
      event_category: 'game',
    });
  },

  giveUp: (language) => {
    window.gtag('event', eventName('give_up', language), {
      event_category: 'game',
    });
  },

  viewTopWords: (language) => {
    window.gtag('event', eventName('view_top_words', language), {
      event_category: 'game',
    });
  },

  selectRandomGame: (language) => {
    window.gtag('event', eventName('select_random_game', language), {
      event_category: 'game',
    });
  },

  selectPreviousGame: (language) => {
    window.gtag('event', eventName('select_previous_game', language), {
      event_category: 'game',
    });
  },

  share: (language) => {
    window.gtag('event', eventName('share_button_click', language), {
      event_category: 'interface',
    });
  },

  instructions: (language) => {
    window.gtag('event', eventName('instructions_menu_click', language), {
      event_category: 'interface',
    });
  },

  previous: (language) => {
    window.gtag('event', eventName('previous_menu_click', language), {
      event_category: 'interface',
    });
  },

  settings: (language) => {
    window.gtag('event', eventName('settings_menu_click', language), {
      event_category: 'interface',
    });
  },

  credits: (language) => {
    window.gtag('event', eventName('credits_menu_click', language), {
      event_category: 'interface',
    });
  },

  languageSetting: (value) => {
    if (!value) return;
    window.gtag('event', `set_language_${value}`, {
      event_category: 'settings',
    });
  },

  tipSetting: (value) => {
    if (!value) return;
    window.gtag('event', `set_tip_${value}`, {
      event_category: 'settings',
    });
  },

  themeSetting: (value) => {
    if (!value) return;
    window.gtag('event', `set_theme_${value}`, {
      event_category: 'settings',
    });
  },
};

export { track };
