import React from 'react';

const Icons = () => {};

Icons.QuestionMark = function QuestionMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
    </svg>
  );
};

Icons.LightBulb = function LightBulb() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
    </svg>
  );
};

Icons.Calendar = function Calendar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
    </svg>
  );
};

Icons.Info = function Info() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
    </svg>
  );
};

Icons.Twitter = function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
    </svg>
  );
};

Icons.Instagram = function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
    </svg>
  );
};

Icons.TikTok = function TikTok() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
    </svg>
  );
};

Icons.Facebook = function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
    </svg>
  );
};

Icons.GiveUp = function GiveUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z" />
    </svg>
  );
};

Icons.Share = function Share() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
    </svg>
  );
};

Icons.Close = function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
};

Icons.Settings = function Settings() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
      <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
    </svg>
  );
};

Icons.Eye = function Eye() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
    </svg>
  );
};

Icons.Random = function Random() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"
      />
      <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z" />
    </svg>
  );
};

Icons.Jogows = function Jogows() {
  return (
    <svg
      width="2128"
      height="474"
      viewBox="0 0 2128 474"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M785.308 118.82C780.188 120.243 773.219 120.955 764.402 120.955C755.585 120.955 748.616 120.101 743.497 118.393C738.377 116.686 734.679 113.84 732.404 109.855C730.413 105.586 729.133 101.887 728.564 98.7559C728.28 95.3407 728.137 90.3602 728.137 83.8145C728.137 77.2687 728.28 72.4305 728.564 69.2999C729.133 66.1693 730.413 62.6118 732.404 58.6274C736.386 51.2278 747.336 47.5281 765.255 47.5281C782.321 47.5281 792.987 51.2278 797.254 58.6274C799.245 62.3272 800.382 65.7424 800.667 68.873C801.236 72.0036 801.52 76.6995 801.52 82.9607V85.522C801.52 91.7832 801.236 96.4791 800.667 99.6097C800.382 102.74 799.102 106.298 796.827 110.282C794.552 114.267 790.712 117.113 785.308 118.82ZM743.923 153.399C749.327 151.691 756.438 150.838 765.255 150.838C774.073 150.838 781.041 151.691 786.161 153.399C791.281 155.107 794.836 158.095 796.827 162.364C799.102 166.348 800.382 170.048 800.667 173.463C801.236 176.594 801.52 181.432 801.52 187.978V363.86C801.52 397.158 790.001 423.768 766.962 443.69C743.923 463.612 718.609 473.573 691.02 473.573C684.478 473.573 679.642 473.289 676.514 472.719C673.385 472.435 669.83 471.296 665.848 469.304C658.452 465.32 654.755 454.932 654.755 438.141C654.755 421.349 657.884 410.819 664.141 406.55C670.114 402.281 678.362 400.146 688.886 400.146C699.126 400.146 708.227 397.301 716.191 391.609C724.155 385.917 728.137 375.671 728.137 360.872V184.989C728.137 175.029 729.417 167.771 731.977 163.218C734.822 158.379 738.804 155.107 743.923 153.399Z" />
      <path d="M816.433 264.82C816.433 230.668 828.379 202.492 852.271 180.294C876.163 158.095 903.468 146.995 934.186 146.995C964.904 146.995 992.067 158.095 1015.67 180.294C1039.28 202.208 1051.09 230.241 1051.09 264.393C1051.09 288.014 1044.97 309.075 1032.74 327.574C1020.51 345.788 1005.58 359.307 987.943 368.129C970.593 376.667 952.532 380.936 933.759 380.936C914.987 380.936 896.784 376.382 879.149 367.275C861.514 357.883 846.582 344.223 834.352 326.293C822.406 308.079 816.433 287.588 816.433 264.82ZM905.601 295.556C914.703 302.387 923.947 305.802 933.333 305.802C942.719 305.802 952.105 302.244 961.491 295.129C970.877 288.014 975.57 277.484 975.57 263.539C975.57 249.594 971.162 239.206 962.344 232.375C953.527 225.545 943.999 222.13 933.759 222.13C923.52 222.13 913.992 225.687 905.174 232.802C896.357 239.917 891.948 250.447 891.948 264.393C891.948 278.053 896.499 288.441 905.601 295.556Z" />
      <path d="M1208.35 348.919C1197.54 363.149 1180.33 370.264 1156.73 370.264C1133.12 370.264 1110.93 359.449 1090.17 337.819C1069.69 315.905 1059.45 290.433 1059.45 261.404C1059.45 232.375 1069.83 206.904 1090.6 184.989C1111.36 163.075 1134.11 152.118 1158.86 152.118C1168.53 152.118 1177.35 153.968 1185.31 157.668C1193.28 161.083 1198.68 164.356 1201.52 167.487C1204.65 170.333 1207.07 173.036 1208.78 175.598C1209.63 167.629 1212.48 161.652 1217.31 157.668C1222.43 153.399 1230.54 151.264 1241.63 151.264C1252.72 151.264 1260.83 152.118 1265.95 153.826C1271.07 155.533 1274.62 158.522 1276.61 162.791C1278.89 166.775 1280.17 170.333 1280.45 173.463C1281.02 176.594 1281.31 181.432 1281.31 187.978V364.714C1281.31 397.443 1269.93 423.768 1247.18 443.69C1224.42 463.897 1198.82 474 1170.38 474C1148.76 474 1128.14 468.735 1108.52 458.205C1088.89 447.675 1079.08 438.141 1079.08 429.603C1079.08 418.503 1083.34 408.684 1091.88 400.146C1097.28 394.17 1101.41 390.043 1104.25 387.766C1107.38 385.49 1110.93 384.351 1114.92 384.351C1118.9 384.351 1123.02 386.059 1127.29 389.474C1140.94 400.004 1155.73 405.269 1171.66 405.269C1182.47 405.269 1191.29 401 1198.11 392.462C1204.94 383.924 1208.35 373.679 1208.35 361.726V348.919ZM1132.41 261.831C1132.41 270.938 1135.96 279.192 1143.07 286.591C1150.47 293.991 1159.29 297.691 1169.53 297.691C1180.05 297.691 1188.73 293.991 1195.55 286.591C1202.38 278.907 1205.79 270.654 1205.79 261.831C1205.79 252.724 1202.52 244.328 1195.98 236.644C1189.44 228.675 1180.62 224.691 1169.53 224.691C1158.43 224.691 1149.47 228.533 1142.65 236.217C1135.82 243.902 1132.41 252.439 1132.41 261.831Z" />
      <path d="M1296.22 264.82C1296.22 230.668 1308.17 202.492 1332.06 180.294C1355.95 158.095 1383.26 146.995 1413.98 146.995C1444.69 146.995 1471.86 158.095 1495.46 180.294C1519.07 202.208 1530.88 230.241 1530.88 264.393C1530.88 288.014 1524.76 309.075 1512.53 327.574C1500.3 345.788 1485.37 359.307 1467.73 368.129C1450.38 376.667 1432.32 380.936 1413.55 380.936C1394.78 380.936 1376.57 376.382 1358.94 367.275C1341.3 357.883 1326.37 344.223 1314.14 326.293C1302.2 308.079 1296.22 287.588 1296.22 264.82ZM1385.39 295.556C1394.49 302.387 1403.74 305.802 1413.12 305.802C1422.51 305.802 1431.9 302.244 1441.28 295.129C1450.67 288.014 1455.36 277.484 1455.36 263.539C1455.36 249.594 1450.95 239.206 1442.13 232.375C1433.32 225.545 1423.79 222.13 1413.55 222.13C1403.31 222.13 1393.78 225.687 1384.96 232.802C1376.15 239.917 1371.74 250.447 1371.74 264.393C1371.74 278.053 1376.29 288.441 1385.39 295.556Z" />
      <path d="M1611.77 306.656C1616.61 311.494 1619.02 321.455 1619.02 336.539V338.246C1619.02 345.077 1618.74 350.199 1618.17 353.615C1617.89 356.745 1616.46 360.587 1613.91 365.141C1609.64 373.394 1598.83 377.521 1581.48 377.521C1564.41 377.521 1553.04 374.817 1547.35 369.41C1541.94 364.002 1539.24 353.757 1539.24 338.673V336.965C1539.24 330.42 1539.38 325.439 1539.67 322.024C1540.24 318.609 1541.66 314.767 1543.94 310.498C1548.2 301.96 1558.87 297.691 1575.93 297.691C1593.28 297.691 1605.23 300.679 1611.77 306.656Z" />
      <path d="M1913.31 153.826C1931.52 161.795 1940.62 171.613 1940.62 183.282C1940.62 188.12 1938.34 195.947 1933.79 206.761C1929.24 217.291 1925.4 226.541 1922.27 234.51L1875.34 348.919C1871.07 361.441 1863.96 370.264 1854.01 375.386C1849.74 377.379 1844.34 378.375 1837.8 378.375C1831.25 378.375 1824.85 375.956 1818.6 371.117C1812.62 365.995 1808.64 360.872 1806.65 355.749L1803.24 348.492C1800.96 342.8 1792.57 320.601 1778.07 281.896C1775.22 288.726 1770.96 299.968 1765.27 315.621C1759.86 330.989 1756.31 340.665 1754.6 344.65C1753.18 348.349 1752.18 350.911 1751.61 352.334C1751.33 353.472 1750.19 355.749 1748.2 359.164C1746.21 362.579 1743.93 365.568 1741.37 368.129C1734.55 374.959 1726.73 378.375 1717.91 378.375C1709.09 378.375 1701.7 375.956 1695.72 371.117C1689.75 365.995 1685.77 360.872 1683.78 355.749L1619.35 198.65C1616.51 190.112 1615.09 184.563 1615.09 182.001C1615.09 170.617 1623.19 161.652 1639.41 155.107C1648.51 151.407 1655.9 149.557 1661.59 149.557C1667.28 149.557 1671.83 150.838 1675.24 153.399C1678.94 155.676 1681.5 158.095 1682.92 160.656C1684.35 162.933 1685.48 165.352 1686.34 167.914C1691.17 179.298 1701.27 206.05 1716.63 248.17L1741.8 179.013C1743.51 173.321 1746.64 168.34 1751.19 164.071C1759.44 155.818 1767.83 151.691 1776.36 151.691C1784.89 151.691 1792.15 153.968 1798.12 158.522C1804.38 163.075 1808.64 167.629 1810.92 172.183L1814.33 179.44C1828.55 219.284 1836.94 242.052 1839.5 247.744L1865.53 176.878C1872.35 158.664 1882.59 149.557 1896.25 149.557C1901.65 149.557 1907.34 150.98 1913.31 153.826Z" />
      <path d="M1948.81 357.03C1943.41 353.615 1940.7 348.207 1940.7 340.808C1940.7 333.408 1946.11 322.166 1956.92 307.083C1960.04 302.244 1965.02 299.825 1971.85 299.825C1978.96 299.825 1988.49 303.383 2000.43 310.498C2012.38 317.328 2023.19 320.743 2032.86 320.743C2050.21 320.743 2058.88 317.328 2058.88 310.498C2058.88 305.09 2049.36 301.106 2030.3 298.545C2012.38 295.983 1994.6 289.295 1976.97 278.48C1968.72 273.358 1961.89 265.389 1956.49 254.574C1951.09 243.475 1948.38 230.383 1948.38 215.299C1948.38 170.333 1979.24 147.849 2040.96 147.849C2061.16 147.849 2081.92 152.687 2103.25 162.364C2113.21 166.917 2118.19 172.609 2118.19 179.44C2118.19 186.27 2115.06 194.666 2108.8 204.627C2102.54 214.588 2096.14 219.568 2089.6 219.568C2086.19 219.568 2079.79 217.434 2070.4 213.165C2061.3 208.896 2051.35 206.761 2040.54 206.761C2023.76 206.761 2015.37 209.892 2015.37 216.153C2015.37 224.691 2025.04 230.241 2044.38 232.802C2063.15 234.794 2081.35 240.202 2098.99 249.024C2107.24 253.009 2114.06 259.981 2119.47 269.942C2125.16 279.619 2128 291.856 2128 306.656C2128 321.455 2125.16 334.119 2119.47 344.65C2114.06 354.895 2106.38 362.579 2096.43 367.702C2078.22 376.525 2055.33 380.936 2027.74 380.936C2000.43 380.936 1974.12 372.967 1948.81 357.03Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.326 99.3079C435.465 70.947 478.896 41.7164 523.962 55.3328C614.189 82.5944 554.903 383.936 465.118 417.073C404.181 439.563 376.16 419.851 339.475 394.043C322.107 381.825 302.797 368.241 277.133 357.122C241.439 341.657 220.309 357.676 197.937 374.637C170.247 395.63 140.654 418.065 79.1891 384.037C-31.9376 322.517 -17.4031 39.0116 68.9013 5.4374C120.766 -14.7391 154.143 24.6956 189.856 66.8914C213.57 94.9104 238.315 124.147 270.188 137.956C310.166 155.277 351.097 127.729 393.326 99.3079ZM105.205 201.617C128.707 201.617 147.759 184.693 147.759 163.817C147.759 142.941 128.707 126.018 105.205 126.018C81.7032 126.018 62.6511 142.941 62.6511 163.817C62.6511 184.693 81.7032 201.617 105.205 201.617ZM153.078 296.115C173.642 296.115 190.313 281.307 190.313 263.041C190.313 244.774 173.642 229.966 153.078 229.966C132.514 229.966 115.844 244.774 115.844 263.041C115.844 281.307 132.514 296.115 153.078 296.115ZM413.182 301.054C439.003 305.407 438.719 293.185 438.45 281.611C438.276 274.093 438.107 266.848 445.104 264.597C452.367 262.259 460.379 263.947 468.039 265.56C482.087 268.519 494.95 271.228 499.828 248.393C504.915 224.582 494.053 223.897 482.418 223.163C475.113 222.702 467.503 222.223 463.345 215.987C459.434 210.12 461.533 202.819 463.604 195.614C467.087 183.498 470.491 171.656 445.104 167.377C418.712 162.928 416.09 175.794 413.687 187.586C412.212 194.825 410.82 201.659 404.061 203.834C396.711 206.2 389.176 204.444 382.182 202.813C369.586 199.878 358.744 197.351 353.897 220.037C348.816 243.822 361.365 244.532 374.311 245.265C382.464 245.727 390.775 246.197 394.94 252.444C398.851 258.31 396.752 265.612 394.681 272.817C391.198 284.933 387.794 296.775 413.182 301.054Z"
      />
    </svg>
  );
};

Icons.Chat = function Chat() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      height="16"
      width="16"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
    </svg>
  );
};

export { Icons };
