import React from 'react';
import { Icons } from '../Icons';
import { useTranslation } from 'react-i18next';

function Feedback(props) {
    const { t } = useTranslation();
    const {formLink}=props;

    const handleButtonClick = () => {
        window.open(formLink,'_blank');
    };

    return (
      <div>
        <div className="modal-title">
            <Icons.Chat />
            <h2>{t('FeedbackTitle')}</h2>
        </div>
        <p>{t('FeedbackText.pt1')}</p>
        <p>{t('FeedbackText.pt2')}</p>
        <div className="modal-btn-div">
            <button className="survey-participate-btn" onClick={handleButtonClick}>{t('FeedbackButton')}</button>
        </div>
      </div>
    );
  }
  
  export { Feedback };