import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';

function Info() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="social-media-links">
        <a href="https://jogo.ws" target="_blank" rel="noreferrer">
          <Icons.Jogows />
        </a>
      </div>
      <p>
        {t('infoModal.p2a')}{' '}
        <a href="https://semantle.com/" target="_blank" rel="noreferrer">
          Semantle
        </a>
        {t('infoModal.p2b')}
      </p>
      <p>
        {t('infoModal.p3')}{' '}
        <a href={t('infoModal.sourceLink')} target="_blank" rel="noreferrer">
          {t('infoModal.sourceName')}
        </a>
        .
      </p>
      <p>
        {t('infoModal.p4')}{' '}
        <a href={t('privacyPolicyLink')} target="_blank" rel="noreferrer">
          {t('privacyPolicy')}
        </a>
        .
      </p>
      <p className="version">
        {t('infoModal.version')} {process.env.REACT_APP_GIT_SHA}
      </p>
    </div>
  );
}

export { Info };
