import React from 'react';
import { useTranslation } from 'react-i18next';

function GiveUp({ onConfirm, onCancel }) {
  const { t } = useTranslation();
  return (
    <div>
      <p style={{ textAlign: 'center' }}>{t('giveUpModalText')}</p>
      <div style={{ textAlign: 'center' }}>
        <button className="share-btn" onClick={onConfirm}>
          {t('Yes')}
        </button>
        <button className="share-btn" onClick={onCancel}>
          {t('No')}
        </button>
      </div>
    </div>
  );
}

export { GiveUp };
