import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCountdown } from '../useCountdown';

function pad(num) {
  const s = '0' + num;
  return s.substring(s.length - 2);
}

function Countdown() {
  const [hours, minutes, seconds] = useCountdown();
  const { t } = useTranslation();

  return (
    <div>
      <div className="countdown-text">{t('countdownText')}</div>
      <div className="countdown">
        {pad(hours)}:{pad(minutes)}:{pad(seconds)}
      </div>
    </div>
  );
}

export { Countdown };
